import store from '@/store/index'
/**
 * 刷新TOKEN
 */
export const refreshToken = (_this) => {
  // 获取用户
  _this
    .POST(this.API_MODULE.SEARCH_USER, {
      account: JSON.parse(window.localStorage.getItem('USER')).account,
    })
    .then((res) => {
      if (res.code != 0 && res.code != 200) {
        _this.$message.error(res.msg)
        return
      }
      const tenantName = res.data.tenantName
      const equipmentName = res.data.equipmentName
      const equipmentLogo = res.data.equipmentLogo
      _this
        .POST(_this.API_MODULE.VERIFY_LOGIN, {
          grantType: 'refresh_token',
          refreshToken: window.localStorage.getItem('REFRESH_TOKEN'),
          snCode: JSON.parse(window.localStorage.getItem('EQUIPMENT')).snCode,
        })
        .then((res) => {
          if (res.code == '0') {
            store.commit('SET_LOGIN_TOKEN', res.data.token)
            window.sessionStorage.setItem('TOKEN', res.data.token)
            window.localStorage.setItem('TOKEN', res.data.token)
            window.localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken)
            window.localStorage.setItem('TIME', res.data.expiration)
            window.sessionStorage.setItem('REFRESH_TIME', new Date().getTime())

            window.localStorage.setItem(
              'USER',
              JSON.stringify({
                id: res.data.userId,
                account: res.data.account,
                name: res.data.name,
                userType: res.data.userType,
                tenantName: tenantName,
                avatar: res.data.avatar,
                workDescribe: res.data.workDescribe,
                equipmentLogo: equipmentLogo,
                equipmentName: equipmentName,
              })
            )
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
}
