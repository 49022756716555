export default {

  // 获取签到活动
  GET_SIGN_IN_ACTIVITY: `/screen/interaction/searchScreenActivityByUser`,

  // 获取试卷列表
  GET_EXAM_LIST: `/screen/interaction/searchScreenExamByUser`,

  // 获取投票列表
  GET_VOTE_LIST: `/screen/interaction/searchScreenVoteByUser`,

  // 获取留言消息列表
  GET_MSG_LIST: `/screen/interaction/searchScreenLeaveCommentsBySnCode`,

  // 获取试卷答题状态数据
  GET_EXAM_STATUS_DATA: `/screen/interaction/searchScreenUserExamByExam`,

  // 答题结束
  ANSWER_END: `/screen/interaction/editStatusEnd`,

  // 投票数据
  GET_VOTE_DATA: `/screen/interaction/searchScreenVoteDetailByVote`,

  // 投票结果
  GET_VOTE_RESULT: `/screen/interaction/searchScreenVoteDetailByVote`,

  // 结束投票
  GET_END_VOTE: `/screen/interaction/editStatus`,
};
