<template>
  <div id="app">
    <audio src="" id="eventAudio" controls="controls" hidden></audio>

    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Base64 } from 'js-base64'
export default {
  name: 'app',

  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    ...mapState(['LoginToken'])
  },
  watch: {
    LoginToken: {
      handler: function (newVal, oldVal) {
        if (newVal && !oldVal) {
          this.timer = setInterval(() => {
            this.refreshToken()
          }, 1 * 60 * 1000)
        }
        if (!newVal && this.timer) {
          clearInterval(this.timer)
        }
      }
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    refreshToken() {
      // 获取用户
      this.POST(this.API_MODULE.SEARCH_USER, {
        account: JSON.parse(window.localStorage.getItem('USER')).account
      }).then((res) => {
        if (res.code != 0 && res.code != 200) {
          this.$message.error(res.msg)
          return
        }
        const tenantName = res.data.tenantName
        const equipmentName = res.data.equipmentName
        const equipmentLogo = res.data.equipmentLogo
        this.POST(this.API_MODULE.VERIFY_LOGIN, {
          grantType: 'refresh_token',
          refreshToken: window.localStorage.getItem('REFRESH_TOKEN'),
          snCode: JSON.parse(window.localStorage.getItem('EQUIPMENT')).snCode
        })
          .then((res) => {
            if (res.code == '0') {
              this.$store.commit('SET_LOGIN_TOKEN', res.data.token)
              window.sessionStorage.setItem('TOKEN', res.data.token)
              window.localStorage.setItem('TOKEN', res.data.token)
              window.localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken)
              window.localStorage.setItem('TENANT', `${Base64.encode(res.data.tenantCode)}`)
              window.localStorage.setItem('TIME', res.data.expiration)
              window.localStorage.setItem(
                'USER',
                JSON.stringify({
                  id: res.data.userId,
                  account: res.data.account,
                  name: res.data.name,
                  userType: res.data.userType,
                  tenantName: tenantName,
                  avatar: res.data.avatar,
                  workDescribe: res.data.workDescribe,
                  equipmentLogo: equipmentLogo,
                  equipmentName: equipmentName
                })
              )
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
