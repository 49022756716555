import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/utils/rem'
import moment from 'moment'

import { GET, POST, DELETE } from './request/httpConfig'
import { API_MODULE } from './request/api'

import * as filters from './utils/filters'

Vue.prototype.OSS_URL = process.env.VUE_APP_FILE_URL
Vue.prototype.SNCODE_STATRUS = process.env.VUE_APP_SNCODE_STATRUS
Vue.prototype.WX_URL = process.env.VUE_APP_WX_URL
Vue.prototype.GET = GET
Vue.prototype.POST = POST
Vue.prototype.DELETE = DELETE

Vue.prototype.API_MODULE = API_MODULE
Vue.prototype.$moment = moment
moment.locale('zh-cn')

Vue.prototype.playOrPausedAudio = (src) => {
  let audio = document.getElementById('eventAudio')
  if (audio.paused) {
    if (audio.currentTime === 0) {
      audio.setAttribute('src', src)
    }
    audio.play()
  } else {
    audio.pause()
  }
}

Vue.prototype.destroyAudio = () => {
  let audio = document.getElementById('eventAudio')
  audio.src = ''
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
