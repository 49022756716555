export default {
  // 大屏根据课程分类id查询课程
  GET_COURSES_BY_CATEGORY: "/basics/studyCollect/searchCategoryByCategory",

  // 大屏根据课程栏目id查询相关课程
  GET_RELATED_COURSES: "/basics/studyCollect/searchColumnByColumnId",

  // 大屏根据课程栏目id查询内容
  GET_COURSE_CHAPTERS: "/basics/studyCollect/searchContentByCategory",

  // 获取课程
  GET_COURSE: "/basics/studyCollect/searchColumnById",

  // 收藏课程
  COLLECT_COURSE: "/basics/studyCollect/add",

  // 取消收藏
  CANCEL_COLLECT: "/basics/studyCollect/delete",

  // 我的课程
  MINE_COURSE: "/basics/studyCollect/searchMyCurriculum",

  //获取签到列表
  GET_SIGN_LIST: `/basics/studyCollect/searchStudySigninByCategory`,

  //大屏更新课程内容观看进度
  UPDATE_CURRICULUM_CHAPTER: `/basics/studyCollect/updateCurriculumChapter`
};
