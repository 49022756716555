import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store"

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login"
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/login/index.vue")
  },
  {
    path: "/layout",
    name: "Layout",
    component: () => import(/* webpackChunkName: "about" */ "../components/layout/index.vue"),
    redirect: "/layout/index",
    children: [
      {
        path: "index",
        name: "Index",
        component: () => import(/* webpackChunkName: "about" */ "../views/index/index.vue")//首页
      },
      {
        path: "app/:code/:id",
        name: "APP",
        component: () => import("../views/index/apps.vue")
      },
      {
        path: "lore",
        name: "Lore",
        component: () => import(/* webpackChunkName: "about" */ "../views/dangjianLore/index.vue") //党建知识
      },
      {
        path: "photos",
        name: "Photos",
        component: () => import(/* webpackChunkName: "about" */ "../views/photos/index.vue") //党建风采
      },
      {
        path: "photoList",
        name: "PhotoList",
        component: () => import(/* webpackChunkName: "about" */ "../views/photos/photoList.vue") //党建风采--相册--照片列表页
      },
      {
        path: "photoPlayMode",
        name: "PhotoPlayMode",
        component: () => import(/* webpackChunkName: "about" */ "../views/photos/playMode.vue") //党建风采--相册--照片播放模式
      },
      {
        path: "news",
        name: "News",
        component: () => import(/* webpackChunkName: "about" */ "../views/news/index.vue") //时政要闻
      },
      {
        path: "classroom",
        name: "Classroom",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/index.vue") //智慧课堂
      },
      {
        path: "directory",
        name: "Directory",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/directory.vue") //智慧课堂--目录
      },
      {
        path: "mine",
        name: " Mine",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/mine.vue") //智慧课堂--我的课程
      },
      {
        path: "videoPlayMode",
        name: "VideoPlayMode",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/playMode.vue") //智慧课堂--播放视频
      },
      {
        path: "signIn",
        name: "SignIn",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/signIn.vue") //智慧课堂--签到
      },
      {
        path: "learn/:id",
        name: "Learn",
        component: () => import(/* webpackChunkName: "about" */ "../views/classroom/info.vue") //智慧课堂--详情页
      },
      {
        path: "panoramic",
        name: "Panoramic",
        component: () => import(/* webpackChunkName: "about" */ "../views/panoramic/index.vue") //红色全景
      },
      {
        path: "dangHistory",
        name: "DangHistory",
        component: () => import(/* webpackChunkName: "about" */ "../views/dangHistory/index.vue") //党史馆
      },
      {
        path: "read",
        name: "Read",
        component: () => import(/* webpackChunkName: "about" */ "../views/dangHistory/read.vue") //阅读党史
      },
      {
        path: "base",
        name: "Base",
        component: () => import(/* webpackChunkName: "about" */ "../views/base/index.vue") //中国地图 红色基地
      },
      {
        path: "baseList",
        name: "BaseList",
        component: () => import(/* webpackChunkName: "about" */ "../views/base/list.vue") //基地列表
      },
      {
        path: "baseInfo",
        name: "BaseInfo",
        component: () => import(/* webpackChunkName: "about" */ "../views/base/info.vue") //基地详情
      },
      {
        path: "promise",
        name: "Promise",
        component: () => import(/* webpackChunkName: "about" */ "../views/promise/index.vue") //誓言
      },
      {
        path: "promiseInfo",
        name: "PromiseInfo",
        component: () => import(/* webpackChunkName: "about" */ "../views/promise/info.vue") //誓言详情
      },
      {
        path: "overview",
        name: "Overview",
        component: () => import(/* webpackChunkName: "about" */ "../views/overview/index.vue") //党组织概况
      },
      {
        path: "bigData",
        name: "BigData",
        component: () => import(/* webpackChunkName: "about" */ "../views/bigData/index.vue") //大数据
      },
      {
        path: "interactive",
        name: "WISDOM_INTERACTION",
        component: () => import('../views/interactive/index.vue'),
        meta: { keepAlive: true }
      },
      {
        path: "interactive/answer",
        name: "Answer",
        component: () => import(/* webpackChunkName: "about" */ "../views/interactive/answer.vue") //智慧互动 - 试卷-答题
      },
      {
        path: "interactive/vote",
        name: "Vote",
        component: () => import(/* webpackChunkName: "about" */ "../views/interactive/vote.vue") //智慧互动 投票
      },
      {
        path: "interactive/vote/result",
        name: "VoteResult",
        component: () => import(/* webpackChunkName: "about" */ "../views/interactive/voteResult.vue") //智慧互动 投票结果
      },
      {
        path: "customize",
        name: "Customize",
        component: () => import('../views/customize/index.vue')
      },
      {
        path: "customize/CATEGORY",
        name: "CustomizeCategory",
        component: () => import('../views/customize/category.vue')
      },
      {
        path: "customize/COLUMN",
        name: "CustomizeColumn",
        component: () => import('../views/customize/column.vue')
      },
      {
        path: "customize/CONTENT",
        name: "CustomizeContent",
        component: () => import('../views/customize/content.vue')
      },
    ]
  },
  {
    path: "/today",
    name: "Today",
    component: () => import(/* webpackChunkName: "about" */ "../views/today/index.vue") //党史上的今天
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 在路由全局钩子beforeEach中，根据keepAlive属性，统一设置页面的缓存性
  // 作用是每次进入该组件，就将它缓存
  if (to.meta.keepAlive) {
    store.commit('SET_KEEP_ALIVE', to.name)
  }
  next()
})

// 解决路由重复问题 原因：VueRouter 版本过高限制了路由重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
