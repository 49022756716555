export const skin = {
  namespaced: true,
  state: {
    useSubject: 'red',
    useRouterList: [],
    useProvince: ""
  },
  mutations: {
    setSubject(state, subject) {
      state.useSubject = subject
    },

    setRouterList(state, routerList) {
      if (!routerList.find((router) => router.name === '首页')) {
        routerList.unshift({ name: '首页', path: '/layout/index' })
      }
      state.useRouterList = routerList
    },
    setProvince(state, province) {
      state.useProvince = province
    }
  },
  getters: {
    useSubject(state) {
      return state.useSubject
    },
    useRouterList(state) {
      return state.useRouterList
    },
    useProvince(state) {
      return state.useProvince
    }
  },
}
