import Home from "./Home";
import Login from "./Login";
import Course from "./Course";
import BigData from "./BigData";
import Interactive from "./Interactive"

export const API_MODULE = {
  ...Home,
  ...Login,
  ...Course,
  ...BigData,
  ...Interactive
};
