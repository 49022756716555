//小数点四舍五入
export function formatToFixed(number, count) {
  if (!count) {
    count = 5
  }
  let perm = Math.pow(10, count)
  if (number) {
    return Math.round(number * perm)
  } else {
    return 0
  }
}